<template>
  <div class="orderReview app-container">
    <baseTable ref="table" class="search-form non-expand-icon" :list="list" :checkAll="hasPayed">
      <!-- 提交时间 -->
      <template #createTimeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <!-- 付款时间： -->
      <template #paymentTimeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <template #orderCodesSearchForm="{ form, prop }">
        <el-input
          clearable
          v-model="originOrderCodes"
          placeholder="请输入订单号"
          @change="search(form, prop)"
        ></el-input>
      </template>

      <template #menuLeft="{ size }">
        <el-button v-if="hasPayed" size="small" type="primary" @click="exportBill" :loading="exportLoading"
          >账单导出</el-button
        >
      </template>
      <template #nickNameSlot="{ row }">
        {{ row.childUserName ? `${row.nickName}-${row.childUserName}` : row.nickName }}
      </template>
      <template #paymentTypeSlot="{ row }">
        {{ paymentType(row) }}
      </template>
      <template #menu="{ row }">
        <template v-if="curTabItem.value === 0">
          <ColorTextBtn
            v-if="checkPermission('externaladmin:financial:orderReview:toEditPage')"
            @click="orderReviewEdit(row.id, row.isLocked)"
            >编辑</ColorTextBtn
          >
        </template>
        <template v-else>
          <ColorTextBtn
            v-if="checkPermission('externaladmin:financial:orderReview:toDetailPage')"
            @click="orderReviewDetail(row.id, row.paymentOrderCode)"
            >查看详情</ColorTextBtn
          >
        </template>
      </template>
    </baseTable>
  </div>
</template>

<script>
import editBtn from './module/editBtn'
import { list } from './tableCols'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import getBaseTableData from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import saveAs from 'jszip/vendor/FileSaver.js'
import { reckoningExport } from '@/api/financial/detailbtn'
import SaleDateTime from '@/components/saleDateTime'
export default {
  name: 'orderReview',
  mixins: [
    baseTable,
    getBaseTableData({
      dataAttrs: {
        postData: {},
        isCheckAllFromBtn: false,
        curTabItem: {}
      }
    })
  ],
  components: {
    editBtn,
    SaleDateTime
  },
  inject: ['refreshPage'],
  props: {},
  data() {
    return {
      //paymentStatus 支付状态： 0未支付，1已支付，2关闭/取消
      exportLoading: false,
      billList: [],
      list,
      sup_this: this,
      originOrderCodes: ''
    }
  },
  computed: {
    hasPayed() {
      return this.curTabItem.value === 1
    },
    resetMergeData() {
      return {
        orderItems: [{ asc: false, column: 'create_time' }]
      }
    },
    paymentType() {
      return (row) => {
        switch (row.paymentType) {
          case 1:
            return '支付宝'
          case 2:
            return '微信'
          case 3:
            return '银行卡'
          case 4:
            return '余额付款'
          case 5:
            return '免支付'
          case 6:
            return '信用额度'
          default:
            return '暂无信息'
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!['/financialManagement/orderReviewEdit', '/financialManagement/orderReviewDetail'].includes(from.path)) {
        vm.refreshPage()
      }
    })
  },
  methods: {
    search(form, prop) {
      form[prop] = this.originOrderCodes.split(' ').filter((v) => v !== '')
      this.searchChange()
    },
    async exportBill() {
      let paymenOrderIds = []
      let paymentOrderListDTO
      let checkAllStatus = this.$refs.table?.checkAllStatus
      console.log('全选----', checkAllStatus)

      if (checkAllStatus == 'all') {
        // 全选
        paymentOrderListDTO = this.postData
      } else {
        this.billList = await this.$refs.table.getSelectionDataAllArr()
        if (this.billList.length === 0) return this.$message.warning('请选择账单')
        this.billList?.map((orderList) => {
          paymenOrderIds.push(orderList.id)
        })
      }

      this.exportLoading = true

      reckoningExport({ paymenOrderIds, paymentOrderListDTO })
        .then((res) => {
          // const fileName = type == 2 ? '按款式导出的发票详情数据' : '按订单导出的发票详情数据'
          const fileName = this.curTabItem.label
          saveAs(res, fileName)
          this.$message.success('导出成功')
        })
        .catch((e) => {
          console.log(e)
          this.$message.error('导出失败')
        })
        .finally(() => {
          this.exportLoading = false
        })
      // console.log('this.reviewdata',this.reviewData.id);
    },

    orderReviewEdit(id, isLocked) {
      this.$router.push({
        query: { paymentOrderId: id, isLocked },
        path: '/financialManagement/orderReviewEdit'
      })
    },
    orderReviewDetail(id, paymentOrderCode) {
      this.$router.push({
        query: { paymentOrderId: id, paymentOrderCode: paymentOrderCode },
        path: '/financialManagement/orderReviewDetail'
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
